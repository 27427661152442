<template>
  <Transition
    name="slide-left"
    mode="out-in"
  >
    <div
      v-if="showIntroduction"
      class="column is-half-desktop is-offset-one-quarter-desktop"
    >
      <PartialFormIntroductie
        :cursusreeks="cursusreeks"
        :wijzigen-url="wijzigenUrl"
        :new-user="formData.newUser"
      />

      <a
        class="button is-success mobile-is-very-large button-right"
        @click="dismissIntroduction"
      >
        <span class="is-hidden-mobile">Begin aanmelden</span>
        <span class="icon mdi mdi-arrow-right" />
      </a>
    </div>

    <FormWizard
      v-if="!showIntroduction"
      :title="title"
      subtitle=""
      color="#00897e"
      error-color="hsl(348, 100%, 61%)"
      next-button-text="Volgende"
      back-button-text="Vorige"
      :finish-button-text="finishButtonText"
      @on-complete="finish"
      @on-change="onChangeStep"
    >
      <h1
        slot="title"
        class="title is-size-1"
      >
        {{ title }}
      </h1>

      <TabContent
        :before-change="validateTab1"
        title="Persoonlijk"
        class="column is-half-desktop is-offset-one-quarter-desktop"
      >
        <!--PERSOONLIJK-->
        <PartialFormPersoonlijk
          ref="partialFormPersoonlijk"
          :data="formData.persoonlijk"
          :read-only="!persoonlijkEdit"
        />

        <!--CONTACT-->
        <ViewAndEditWrapper
          v-if="!contactEdit"
          key="view-and-edit-wrapper-contact"
          :data="formData.contact"
          title="Contactgegevens"
          disable-save
        >
          <template #default="slotProps">
            <PartialFormContact
              ref="partialFormContact"
              :read-only="!slotProps.editing"
              :data="slotProps.data"
              no-title
            />
          </template>
        </ViewAndEditWrapper>
        <PartialFormContact
          v-else
          ref="partialFormContact"
          :data="formData.contact"
          :persoonlijk="formData.persoonlijk"
        />

        <!--SCHOOL-->
        <ViewAndEditWrapper
          v-if="!schoolEdit"
          key="view-and-edit-wrapper-school"
          :data="formData.school"
          title="School en opleiding"
          disable-save
        >
          <template #default="slotProps">
            <PartialFormSchool
              ref="partialFormSchool"
              :read-only="!slotProps.editing"
              :data="slotProps.data"
              :school-opties="schoolOpties"
              no-title
            />
          </template>
        </ViewAndEditWrapper>
        <PartialFormSchool
          v-else
          ref="partialFormSchool"
          :data="formData.school"
          :school-opties="schoolOpties"
        />

        <!--EXTRA-->
        <PartialFormExtra
          v-show="formData.newUser"
          ref="partialFormExtra"
          :data="formData.extra"
          :via-opties="viaOpties"
          :via-alternatief="viaAlternatief"
        />
        &nbsp;<br />
        <div class="warnings-and-errors">
          <BMessage
            v-if="!uniqueUser"
            key="b-message-unique"
            type="is-warning"
            has-icon
          >
            Het lijkt erop dat je je al eerder hebt aangemeld voor een van onze cursussen.
            In ons <a :href="wijzigenUrl">inloggedeelte</a> kun je cursussen toevoegen of je vakken aanpassen.
            Gebruik de inloggegevens die je eerder per mail hebt ontvangen om hier in te loggen.
            Je persoonlijke gegevens zijn hier al voor je bewaard.
          </BMessage>
          <BMessage
            v-else-if="tab1Invalid"
            key="b-message-validation"
            type="is-danger"
            has-icon
          >
            Je hebt nog niet alle verplichte velden ingevuld.
          </BMessage>
        </div>
      </TabContent>


      <TabContent
        :before-change="validateTab2"
        title="Beschikbaarheid"
        class="column is-half-desktop is-offset-one-quarter-desktop"
      >
        <PartialFormVakken
          ref="partialFormVakken"
          :data="formData.vakken"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="formData.school.Niveau"
        />
        <PartialFormBeschikbaarheid
          ref="partialFormBeschikbaarheid"
          :data="formData.beschikbaarheid"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="formData.school.Niveau"
          :vakken="formData.vakken.vakken"
          :cursusreeks="cursusreeks"
          :beschikbaarheid-min-aantal="instellingen.BeschikbaarheidMinAantal"
        />

        <div class="warnings-and-errors">
          <BMessage
            v-if="!minimumOneSubject"
            key="b-message-minimum-one-subject"
            type="is-danger"
            has-icon
          >
            Kies minstens een vak waarvoor je een cursus wilt volgen.
          </BMessage>
          <BMessage
            v-else-if="cijfersInvalid"
            key="b-message-cijfers-invalid"
            type="is-danger"
            has-icon
          >
            Geef voor elk vak je SE-cijfer op in een decimaal nauwkeurig.
          </BMessage>
        </div>
      </TabContent>

      <TabContent
        :before-change="validateTab3"
        title="Voorkeur"
      >
        <PartialFormVoorkeur
          :data="formData.voorkeur"
          :vakken-overzicht="vakkenOverzicht"
          :niveau="formData.school.Niveau"
          :vakken="formData.vakken.vakken"
          :indelingen="formData.vakken.indelingen"
          :beschikbaarheid="formData.beschikbaarheid"
          :vraag-om-voorkeurslengte="vraagOmVoorkeurslengte"
          :standaard-uitklappen="cursusGeselecteerdViaUrl"
        />

        <!--BIJZONDERHEDEN-->
        <PartialFormBijzonderheden
          :data="formData.bijzonderheden"
        />
        <br />

        <div class="has-text-right">
          <p>
            Door op 'Inschrijven' te klikken, ga je akkoord met de <a
              :href="algemeneVoorwaardenUrl"
              target="_blank"
            >Algemene Voorwaarden</a>.
          </p>
        </div>
        <div class="warnings-and-errors">
          <BMessage
            v-if="geenIndeling.length > 0"
            key="warning-nog-niet-ingedeeld"
            type="is-warning"
            has-icon
            title="Waarschuwing"
          >
            We hebben je voor een aantal vakken (nog) niet kunnen indelen.
            Geef voor de volgende vakken meer beschikbaarheid of voorkeuren op om zeker te zijn van een plaats:
            <ul>
              <li
                v-for="vak in geenIndeling"
                :key="vak.Vak_ID"
              >
                {{ vak.naam }}
              </li>
            </ul>
          </BMessage>
          <BMessage
            v-if="!uniqueUser"
            key="warning-unieke-gebruiker"
            type="is-warning"
            has-icon
          >
            Het lijkt erop dat je je al eerder hebt aangemeld voor een van onze cursussen.
            In ons <a :href="wijzigenUrl">inloggedeelte</a> kun je cursussen toevoegen of je vakken aanpassen.
            Gebruik de inloggegevens die je eerder per mail hebt ontvangen om hier in te loggen.
            Je persoonlijke gegevens zijn hier al voor je bewaard.
          </BMessage>
        </div>
      </TabContent>
      <template
        slot="prev"
      >
        <a class="button is-success mobile-is-very-large">
          <span class="icon mdi mdi-arrow-left" />
          <span class="is-hidden-mobile">Vorige</span>
        </a>
      </template>
      <template
        slot="next"
      >
        <a class="button is-success mobile-is-very-large">
          <span class="is-hidden-mobile">Volgende</span>
          <span class="icon mdi mdi-arrow-right" />
        </a>
      </template>
      <template
        slot="finish"
      >
        <a
          class="button is-success"
          :class="[{ 'is-loading': isSaving }, 'button', 'is-success']"
          :disabled="!formValid"
        >
          <span>Inschrijven</span>
          <span class="icon mdi mdi-send" />
        </a>
      </template>
    </FormWizard>
  </Transition>
</template>

<script>
  import {BMessage} from 'buefy/src/components/message';

  import {computed, ref, set as setReactive} from 'vue';
  import {FormWizard, TabContent} from 'vue-form-wizard';
  import 'vue-form-wizard/dist/vue-form-wizard.min.css';

  import PartialFormBeschikbaarheid from '../partials/PartialFormBeschikbaarheid.vue';
  import PartialFormBijzonderheden from '../partials/PartialFormBijzonderheden.vue';
  import PartialFormContact from '../partials/PartialFormContact.vue';
  import PartialFormExtra from '../partials/PartialFormExtra.vue';
  import PartialFormIntroductie from '../partials/PartialFormIntroductie.vue';
  import PartialFormPersoonlijk from '../partials/PartialFormPersoonlijk.vue';
  import PartialFormSchool from '../partials/PartialFormSchool.vue';
  import PartialFormVakken from '../partials/PartialFormVakken.vue';
  import PartialFormVoorkeur from '../partials/PartialFormVoorkeur.vue';
  import useBeforeUnload from '../util/beforeUnload';
  import useCursusViaUrl from '../util/cursusViaUrl';
  import ViewAndEditWrapper from '../wrappers/ViewAndEditWrapper.vue';

  import usePostRequest from '@/js/vue/util/postRequest';

  export default {
    name: 'RegisterForm',
    components: {
      BMessage,
      FormWizard,
      TabContent,
      PartialFormIntroductie,
      PartialFormContact,
      PartialFormPersoonlijk,
      PartialFormSchool,
      PartialFormExtra,
      PartialFormVakken,
      PartialFormBeschikbaarheid,
      PartialFormVoorkeur,
      PartialFormBijzonderheden,
      ViewAndEditWrapper,
    },
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
      initialData: {
        type: Object,
        default: () => ({}),
      },
      auth: {
        type: String,
        required: true,
      },
      algemeneVoorwaardenUrl: {
        type: String,
        required: true,
      },
      redirectUrl: {
        type: String,
        required: true,
      },
      wijzigenUrl: {
        type: String,
        default: '/login',
      },
    },
    setup(props) {
      const isDirty = ref(false);
      useBeforeUnload(isDirty);

      const uniqueUser = ref(true);

      const vakkenOverzicht = ref({});
      const cursusGeselecteerdViaUrl = ref(false);
      const {periodeVakFromParams} = useCursusViaUrl(vakkenOverzicht);

      const postEndpoint = computed(() => `/api/registratie/${props.cursusreeks.slug}`);
      const handlePostResponse = ref();
      const onPostRequestError = (data) => {
        uniqueUser.value = data?.unique ?? uniqueUser.value;
      };
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse, () => {/* do nothing*/}, onPostRequestError);
      postRequestErrorTitle.value = 'Fout opgetreden bij het inschrijven';

      return {
        isDirty,
        uniqueUser,
        vakkenOverzicht,
        cursusGeselecteerdViaUrl,
        periodeVakFromParams,
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        formData: {
          persoonlijk: {
            Voornaam: '',
            Tussenvoegsel: '',
            Achternaam: '',
            Geslacht: '',
            Geboortedatum: null,
          },
          contact: {
            Straat: '',
            Huisnummer: '',
            Postcode: '',
            Plaats: '',
            telefoonnummers: {
              mobiel: {Telefoonnummer: '', Type: 'Eigen nummer', optioneel: false},
              ouder: {Telefoonnummer: '', Type: '', Opmerkingen: '', optioneel: false},
              thuis: {Telefoonnummer: '', Type: 'Overig', Opmerkingen: 'Huisnummer', optioneel: true},
            },
            Email1: '',
            Email2: '',
          },
          school: {
            School_ID: undefined,
            School: '',
            SchoolPlaats: '',
            SchoolHandmatig: false,
            Niveau: '',
            Profiel: '',
          },
          extra: {
            via: {
              id: undefined,
              anders: '',
            },
          },
          vakken: {
            vakken: [],
            cijfers: {},
            indelingen: {},
          },
          beschikbaarheid: {},
          voorkeur: {
            voorkeursperiode: {},
            voorkeurslengte: {},
            opmerkingen: {},
          },
          bijzonderheden: {
            opmerkingen: '', // niet aanpasbaar veld voor opmerkingen van vorige cursusreeks(en)
            opmerkingenNieuw: '',
          },
          newUser: true,
        },
        viaOpties: [],
        schoolOpties: [],
        viaAlternatief: {},
        instellingen: {},
        geenIndeling: [],
        vraagOmVoorkeurslengte: false,
        activeStep: 0,
        indelingAlertGiven: false,
        persoonlijkEdit: true,
        bijzonderhedenEdit: true,
        schoolEdit: true,
        contactEdit: true,
        formValid: false,
        isMounted: false,
        tab1NextClicked: false,
        showIntroduction: true,
      };
    },
    computed: {
      title() {
        return this.cursusreeks.Naam;
      },
      validatieElementen() {
        return [
          {
            ref: this.$refs.partialFormPersoonlijk,
            tab: 1,
            verplicht: this.formData.newUser,
          },
          {
            ref: this.$refs.partialFormContact,
            tab: 1,
            verplicht: true,
          },
          {
            ref: this.$refs.partialFormSchool,
            tab: 1,
            verplicht: true,
          },
          {
            ref: this.$refs.partialFormExtra,
            tab: 1,
            verplicht: true,
          },
          {
            ref: this.$refs.partialFormVakken,
            tab: 2,
            verplicht: true,
          },
          {
            ref: this.$refs.partialFormBeschikbaarheid,
            tab: 2,
            verplicht: true,
          },
        ];
      },
      tab1Invalid() {
        return this.isMounted && this.tab1NextClicked && this.validatieElementen
          .filter((entry) =>
            entry.tab === 1 && entry.verplicht && (entry.ref !== undefined))
          .reduce((result, entry) => result || entry.ref.$v.$invalid, false);
      },
      minimumOneSubject() {
        if (this.isMounted && this.$refs.partialFormVakken) {
          return this.$refs.partialFormVakken.$v.data.vakken.minimumOne;
        }
        return true;
      },
      cijfersInvalid() {
        if (this.isMounted && this.$refs.partialFormVakken) {
          return !this.$refs.partialFormVakken.$v.data.vakken.elkVakCijfer
            || this.$refs.partialFormVakken.$v.data.cijfers.$invalid;
        }
        return false;
      },
      finishButtonText() {
        return 'Inschrijven';
      },
    },
    watch: {
      auth: {
        immediate: true,
        handler(newVal) {
          this.formData.newUser = (newVal !== '1');
        },
      },
      'formData.school.Niveau': function() {
        // bij switch van niveau: gooi oude vakken weg (die niet bij het nieuwe niveau horen)
        const huidigGeselecteerdeNiveau = this.formData.school.Niveau;
        const vakkenLijst = this.formData.vakken.vakken;
        this.formData.vakken.vakken = vakkenLijst.filter((vak) => vak.niveau === huidigGeselecteerdeNiveau);
        this.formData.vakken.indelingen = {};
        this.formData.vakken.cijfers = {};
        if (this.$refs.partialFormVakken) {
          this.$refs.partialFormVakken.$v.data.$reset();
        }
      },
      'formData.vakken.indelingen': function() {
        this.indelingAlertGiven = false;
      },
      'formData.beschikbaarheid': function() {
        this.indelingAlertGiven = false;
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
      this.isMounted = true;
      $http.get('/api/overzicht/' + this.cursusreeks.slug)
        .then((response) => {
          this.vakkenOverzicht = response.data.overzicht;
          if (this.vakkenOverzicht.periodes.length === 1) {
            // Voor cursussen met maar 1 periode zetten we standaard de beschikbaarheid op 'Ja'
            const periodeID = this.vakkenOverzicht.periodes[0].Periode_ID;
            setReactive(this.formData.beschikbaarheid, periodeID, '1');
          }
          this.instellingen = response.data.instellingen;
          this.vraagOmVoorkeurslengte = this.instellingen.VraagOmVoorkeurslengte;
          this.viaOpties = response.data.via;
          this.schoolOpties = response.data.scholen;
          if (this.initialData.persoonlijk !== undefined) {
            this.persoonlijkEdit = false;
            this.formData.persoonlijk = this.initialData.persoonlijk;
          }
          if (this.initialData.contact !== undefined) {
            this.contactEdit = false;
            this.formData.contact = this.initialData.contact;
          }
          if (this.initialData.school !== undefined) {
            this.schoolEdit = false;
            this.formData.school = this.initialData.school;
          }
          if (this.initialData.bijzonderheden !== undefined) {
            this.bijzonderhedenEdit = false;
            this.formData.bijzonderheden = this.initialData.bijzonderheden;
            this.formData.bijzonderheden.opmerkingenNieuw = '';
          }

          const [gevondenPeriode, gevondenVak] = this.periodeVakFromParams();
          if (gevondenVak && gevondenPeriode) {
            // Als er een vak + periode gevonden is
            this.formData.vakken.vakken.push(gevondenVak); // voeg vak toe aan geselecteerde vakken
            this.formData.school.Niveau = gevondenVak.niveau;
            this.formData.beschikbaarheid[gevondenPeriode.Periode_ID] = '1';
            this.formData.voorkeur.voorkeursperiode[gevondenVak.Vak_ID] = gevondenPeriode.Periode_ID;
            this.cursusGeselecteerdViaUrl = true;
          }
        });
    },
    methods: {
      dismissIntroduction() {
        this.showIntroduction = false;
        this.isDirty = true;
      },
      finish() {
        if (this.checkIfFormValid() && !this.isSaving) {
          this.postRequest(this.formData);
        }
      },
      onPostRequestSucces() {
        this.isDirty = false;
        gtag('event', 'sign_up', {
          'event_label': this.title,
        });
        history.pushState(null, 'inschrijving geslaagd', this.redirectUrl);
        window.history.go();
      },
      onChangeStep(prevIndex, nextIndex) {
        window.location = `#stap-${nextIndex + 1}`;
        gtag('event', 'page_view', {
          page_title: document.title,
          page_location: location.pathname + location.search + location.hash,
        });
        this.checkIfFormValid();
        window.scrollTo(0, 0); // Scroll naar boven
      },
      validateTab1() {
        this.tab1NextClicked = true;
        this.validatieElementen.filter((entry) => entry.tab === 1).forEach((entry) => {
          entry.ref.$v.$touch();
        });

        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          return true;
        }

        if (!this.$refs.partialFormContact.$v.data.uniqueUser) {
          this.$buefy.dialog.alert('Je bent al aangemeld voor een van onze cursussen');
          this.uniqueUser = false;
          return false;
        }
        this.uniqueUser = true;

        const validatieFoutVerplicht = this.validatieElementen
          .filter((entry) => entry.tab === 1 && entry.verplicht)
          .reduce((result, entry) => result || entry.ref.$v.$invalid, false);

        if (validatieFoutVerplicht) {
          return false;
        }
        return true;
      },
      validateTab2() {
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          return true;
        }

        this.$refs.partialFormVakken.$v.data.$touch();
        if (!this.$refs.partialFormVakken.$v.data.vakken.minimumOne) {
          this.$buefy.dialog.alert('Kies minstens 1 vak waarvoor je een cursus wilt volgen');
          return false;
        }
        if (!this.$refs.partialFormVakken.$v.data.vakken.elkVakCijfer) {
          this.$buefy.dialog.alert('Geef voor elk vak je cijfer op');
          return false;
        }
        if (this.$refs.partialFormVakken.$v.data.cijfers.$invalid) {
          this.$buefy.dialog.alert('Er is iets mis met je cijfers');
          return false;
        }

        this.$refs.partialFormBeschikbaarheid.$v.data.$touch();
        if (!this.$refs.partialFormBeschikbaarheid.$v.data.elkVakMinstens) {
          this.$buefy.dialog.alert('Vul voor elk vak minstens 1 periode Ja in');
          return false;
        } else if (!this.$refs.partialFormBeschikbaarheid.$v.data.beschikbaarheidGroterDanVakken) {
          this.$buefy.dialog.alert('Vul voor voldoende periodes Ja in');
          return false;
        } else if (!this.$refs.partialFormBeschikbaarheid.$v.data.elkVakMinstensAangeraden) {
          gtag('event', 'elkVakMinstensAangeraden', {
            'event_category': 'validatie',
            'event_label': this.title,
          });
        }
        return true;
      },
      validateTab3() {
        this.geenIndeling = this.genereerIndeling();
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          return true;
        }
        if (this.geenIndeling.length > 0 && !this.indelingAlertGiven) {
          this.$buefy.dialog.alert('Het is ons niet gelukt om je voor al je vakken in te delen');
          this.indelingAlertGiven = true;
          return false;
        }
        return true;
      },
      beschikbarePeriodesBijVak(vakID, indelingen) {
        const vakPeriodes = this.vakkenOverzicht.cursussen[this.formData.school.Niveau]
          .find((vak) => vak.Vak_ID === vakID).periodes;

        return Object.keys(vakPeriodes)
          .filter((id) => (this.formData.beschikbaarheid[id] === '1' && !(id in indelingen))) // Alleen beschikbare periodes tonen
          .map(Number);
      },
      genereerIndeling() {
        const res = [];
        const vakkenIngedeeld = Object.values(this.formData.vakken.indelingen);
        const nieuweIndelingen = [];
        const itdv = this.formData.vakken.vakken
          .filter((vak) => !vakkenIngedeeld.includes(vak.Vak_ID))
          .map((vak) => [vak, this.beschikbarePeriodesBijVak(vak.Vak_ID, this.formData.vakken.indelingen)])
          .sort((a, b) => (a[1].length > b[1].length) ? 1 : -1);
        itdv.forEach((vak) => {
          vak[1] = vak[1].filter((periode) => !nieuweIndelingen.includes(periode));
          const per = this.deelVakIn(vak);
          if (per !== 0) {
            this.formData.vakken.indelingen[per] = vak[0].Vak_ID;
            nieuweIndelingen.push(per);
          } else {
            res.push(vak[0]);
          }
        });
        return res;
      },
      deelVakIn: function(vakPeriodes) {
        const vak = vakPeriodes[0];
        const periodes = vakPeriodes[1];
        let wachtlijstOptie = true;
        const voorkeursLengte = this.formData.voorkeur.voorkeurslengte[vak.Vak_ID];
        const voorkeursPeriode = this.formData.voorkeur.voorkeursperiode[vak.Vak_ID];
        if (periodes.includes(voorkeursPeriode)) {
          // de voorkeursperiode blijkt toch beschikbaar! =D
          return voorkeursPeriode;
        }
        const lengte = (voorkeursLengte === 'drieDaags') ? 3 : (voorkeursLengte === 'tweeDaags') ? 2 : 0;
        let res = 0;
        for (const periode of periodes) {
          const vakperiode = vak.periodes[periode];
          if (vakperiode.status === 'Open') {
            if ((lengte === 0 || lengte === (vakperiode.dagen.length - vakperiode.nietopdagen.length))) {
              // eerste open periode met juiste lengte = bingo!
              return vakperiode.Periode_ID;
            } else if (res === 0 || wachtlijstOptie) {
              // er is tot nu toe geen of alleen een wachtlijstoptie gevonden: sla deze periode op als beter alternatief
              wachtlijstOptie = false;
              res = vakperiode.Periode_ID;
            }
          } else if (vakperiode.status === 'Wachtlijst' && (res === 0)) {
            // er is tot nu toe geen optie gevonden: sla deze periode op als beter alternatief
            res = vakperiode.Periode_ID;
          }
        }
        return res;
      },
      checkIfFormValid: function() {
        if (import.meta.env.VITE_FORM_ALLOW_INVALID === 'true') {
          this.formValid = true;
          return true;
        }
        this.formValid = !this.validatieElementen
          .filter((entry) => entry.verplicht)
          .reduce((result, entry) => result || entry.ref.$v.$invalid, false);
        this.uniqueUser = this.$refs.partialFormContact.$v.data.uniqueUser;
        return this.formValid;
      },
    },
    validations: {},
  };
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -- Remove this non-scoped block; see #298 -->
<style lang="scss" src="@/sass/vueFormWizard.scss" />
